import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 899.000000 738.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,738.000000) scale(0.100000,-0.100000)">



<path d="M0 3690 l0 -3690 4495 0 4495 0 0 3690 0 3690 -4495 0 -4495 0 0
-3690z m4835 2711 c192 -33 303 -75 411 -156 63 -48 90 -79 120 -136 29 -58
90 -111 169 -149 278 -131 304 -141 351 -142 32 -1 64 6 91 20 76 39 206 22
338 -46 154 -78 388 -313 527 -527 68 -105 98 -176 124 -298 19 -92 18 -136
-6 -207 -17 -50 -19 -69 -11 -125 12 -80 35 -144 76 -210 33 -53 88 -105 109
-105 25 0 102 -60 137 -107 131 -176 183 -378 183 -708 0 -184 -16 -307 -50
-392 -55 -138 -128 -229 -203 -252 -64 -19 -115 -70 -141 -144 -12 -34 -40
-105 -61 -158 -43 -103 -68 -194 -69 -246 0 -18 9 -55 20 -83 22 -57 25 -117
10 -206 -13 -71 -63 -204 -99 -259 -154 -239 -399 -483 -566 -561 -149 -70
-251 -81 -337 -38 -69 35 -126 30 -233 -21 -126 -59 -133 -62 -265 -114 -145
-57 -189 -90 -230 -171 -41 -81 -124 -146 -252 -196 -102 -40 -241 -58 -443
-58 -292 0 -478 41 -623 138 -81 54 -91 64 -134 141 -46 82 -92 113 -298 201
-191 81 -251 92 -320 58 -26 -13 -50 -15 -119 -11 -90 6 -139 20 -233 66 -166
83 -432 343 -569 556 -90 140 -132 332 -94 431 27 71 16 159 -35 280 -68 162
-114 226 -186 260 -86 41 -195 210 -232 359 -23 90 -52 330 -52 426 0 89 27
313 46 379 19 66 73 169 119 225 18 22 58 53 89 69 70 35 108 86 146 192 15
44 36 99 47 124 62 137 74 215 47 309 -15 55 -16 73 -5 142 14 95 41 174 82
248 111 199 399 499 572 595 123 68 271 93 366 61 64 -21 122 -15 201 22 100
46 294 124 368 149 125 41 169 75 192 148 32 100 172 192 350 230 103 23 438
21 575 -3z"/>
<path d="M4375 6340 c-131 -9 -199 -30 -253 -76 -29 -24 -41 -43 -46 -72 -3
-22 -15 -51 -26 -65 -11 -14 -20 -27 -20 -29 0 -2 30 -3 68 -1 64 3 66 2 37
-11 -16 -7 -61 -21 -98 -30 -38 -9 -88 -29 -113 -47 -71 -49 -174 -91 -302
-124 -34 -8 -84 -28 -110 -44 -65 -38 -166 -78 -248 -97 -63 -14 -214 -93
-252 -131 -7 -7 -18 -13 -23 -13 -19 0 13 41 61 78 27 20 49 39 49 42 1 3 -30
16 -68 29 -79 27 -125 24 -198 -15 -162 -87 -493 -434 -583 -613 -30 -60 -38
-150 -17 -199 9 -20 17 -45 19 -55 2 -14 14 -3 44 40 54 77 252 282 334 346
74 58 120 104 162 162 34 47 158 175 170 175 13 0 9 -16 -7 -30 -8 -6 -39 -53
-69 -104 -89 -149 -140 -198 -316 -309 -100 -62 -110 -72 -165 -153 -91 -134
-156 -289 -250 -594 -22 -71 -68 -170 -103 -223 -29 -44 -51 -109 -72 -214
-17 -86 -34 -71 -26 24 3 41 3 73 -2 71 -4 -2 -32 -16 -63 -30 -90 -42 -120
-93 -146 -243 -30 -178 -15 -560 28 -720 23 -86 93 -185 131 -185 5 0 22 -9
38 -20 15 -11 30 -17 32 -14 3 2 2 24 -3 47 -27 147 -17 296 11 160 28 -133
72 -256 155 -428 63 -131 115 -263 115 -292 0 -25 104 -232 155 -307 25 -37
43 -71 40 -75 -8 -7 -91 82 -138 147 -33 46 -47 53 -47 22 0 -12 -9 -39 -21
-62 -24 -47 -21 -100 12 -176 53 -124 274 -374 473 -537 131 -107 232 -138
314 -96 23 11 50 21 62 21 11 0 20 5 20 10 0 6 -4 10 -8 10 -14 0 -137 118
-131 124 4 4 35 -13 70 -37 84 -59 171 -101 239 -117 69 -16 130 -39 250 -97
52 -24 106 -50 120 -56 14 -7 47 -24 73 -40 58 -33 270 -105 335 -114 26 -3
56 -12 67 -19 17 -13 15 -14 -30 -14 -28 0 -83 5 -124 10 -41 6 -76 8 -78 7
-1 -2 6 -21 17 -42 11 -22 20 -44 20 -51 0 -21 55 -67 115 -95 93 -44 241 -63
485 -62 235 0 352 16 440 60 50 26 58 35 92 103 21 41 38 76 38 78 0 1 -44 0
-97 -3 -91 -6 -96 -5 -73 9 14 8 34 15 45 16 49 0 205 55 269 92 37 23 109 57
160 75 50 19 145 61 211 93 69 34 156 68 204 79 163 40 292 142 370 295 56
108 143 189 313 294 29 18 63 40 76 48 12 9 22 12 22 6 0 -16 -98 -113 -165
-164 -68 -52 -116 -102 -199 -205 -32 -40 -74 -87 -95 -104 -45 -39 -161 -150
-161 -155 0 -2 10 -4 21 -4 12 0 46 -14 76 -31 61 -35 100 -35 178 0 170 76
541 481 599 654 22 65 20 129 -4 174 -11 21 -20 48 -20 60 0 41 -16 32 -57
-30 -47 -71 -148 -185 -157 -176 -3 3 15 37 40 75 89 132 184 354 235 543 30
111 76 225 116 284 35 51 48 88 73 200 16 71 33 61 28 -17 -1 -31 -3 -56 -3
-56 0 5 104 50 114 50 49 0 103 77 128 182 25 106 25 469 -1 623 -30 180 -79
280 -154 311 -20 9 -54 25 -74 35 -47 24 -50 24 -38 -3 5 -13 13 -58 19 -101
21 -150 8 -228 -18 -109 -20 89 -72 251 -102 317 -14 33 -43 96 -64 140 -21
44 -53 125 -72 180 -44 127 -127 300 -187 389 -25 38 -43 72 -40 75 10 10 112
-109 168 -193 l51 -79 6 34 c3 19 13 48 22 66 38 72 12 176 -76 308 -58 87
-215 271 -296 348 -202 190 -297 231 -407 172 -26 -14 -55 -25 -65 -25 -11 0
-19 -2 -19 -3 0 -6 100 -105 157 -157 27 -25 67 -67 88 -95 60 -77 114 -132
188 -192 99 -80 187 -173 164 -173 -5 0 -166 105 -226 147 -75 54 -118 103
-192 223 -100 163 -175 222 -340 266 -97 25 -191 67 -339 151 -96 55 -251 125
-337 152 -104 34 -171 51 -195 51 -13 0 -33 7 -44 16 -17 14 -11 15 75 9 52
-3 126 -13 164 -21 39 -8 72 -12 75 -10 2 3 -5 18 -16 34 -11 15 -24 40 -27
53 -12 48 -80 104 -163 133 -86 31 -415 70 -532 64 -14 0 -74 -4 -135 -8z
m160 -253 c33 0 128 4 210 8 116 6 147 5 139 -4 -6 -7 -36 -16 -65 -21 -30 -5
-92 -18 -139 -30 -97 -25 -215 -23 -310 4 -30 9 -82 21 -114 26 -32 5 -64 14
-70 21 -8 9 24 10 139 4 83 -4 177 -8 210 -8z m84 -188 c36 -6 77 -17 91 -24
14 -7 68 -16 120 -19 147 -11 233 -42 311 -113 49 -44 77 -97 86 -158 5 -40
12 -51 61 -89 48 -38 62 -44 120 -50 37 -4 118 -16 182 -26 80 -13 132 -17
170 -12 72 10 132 -1 200 -37 30 -16 88 -46 129 -67 95 -47 186 -137 230 -225
17 -35 53 -88 79 -118 104 -118 142 -201 142 -310 0 -75 -17 -128 -57 -175
-17 -20 -22 -37 -19 -55 3 -14 8 -45 11 -68 5 -29 22 -63 56 -108 45 -59 53
-65 87 -65 76 -1 156 -56 209 -147 42 -71 55 -121 63 -238 5 -60 16 -137 24
-170 18 -67 20 -149 5 -215 -6 -25 -17 -83 -24 -129 -15 -88 -56 -187 -98
-234 -14 -15 -48 -40 -75 -55 -40 -22 -66 -49 -126 -132 -42 -58 -85 -116 -96
-130 -13 -16 -24 -53 -31 -105 l-11 -80 36 -53 c41 -61 51 -101 43 -182 -8
-95 -57 -185 -160 -296 -21 -23 -53 -68 -72 -100 -40 -68 -115 -142 -183 -181
-26 -15 -65 -41 -87 -59 -105 -84 -222 -112 -321 -78 -43 14 -55 14 -143 0
-53 -9 -125 -19 -160 -23 -60 -6 -69 -10 -177 -91 -82 -61 -121 -97 -141 -130
-32 -57 -48 -74 -93 -104 -53 -35 -107 -56 -189 -73 -41 -9 -79 -19 -85 -24
-20 -17 -133 -41 -187 -41 -62 0 -169 23 -202 44 -12 7 -62 19 -112 25 -155
21 -265 93 -309 203 -20 50 -33 65 -102 118 -43 33 -88 63 -99 65 -125 26
-226 31 -372 20 -101 -9 -153 7 -244 70 -35 25 -71 45 -79 45 -21 0 -108 52
-155 93 -49 42 -112 133 -131 187 -8 22 -36 67 -63 101 -93 115 -124 271 -73
367 21 41 22 48 11 135 -6 51 -17 106 -24 122 -18 41 -102 145 -123 151 -130
40 -223 165 -238 320 -4 41 -17 89 -31 117 -62 123 -68 268 -18 388 15 35 34
84 43 109 46 136 82 195 143 239 21 16 82 86 134 157 l96 128 14 90 c12 86 12
93 -7 131 -59 114 -21 275 97 413 9 10 26 48 38 83 44 131 159 243 298 289 33
12 77 31 97 45 64 41 134 58 215 52 48 -4 123 1 227 16 l155 21 140 102 c154
113 154 113 207 177 48 58 109 93 203 117 42 11 80 22 86 25 14 9 130 33 163
34 16 1 59 -4 95 -10z m-2640 -2110 c5 -30 17 -82 27 -115 23 -78 23 -248 1
-324 -9 -30 -21 -85 -27 -122 -7 -38 -16 -68 -20 -68 -16 0 -19 65 -7 168 14
128 14 233 0 362 -17 152 5 232 26 99z m5147 26 c3 -20 -1 -96 -10 -170 -15
-124 -15 -146 0 -280 12 -104 14 -154 7 -178 -12 -39 -13 -38 -48 116 -36 154
-36 262 -1 407 14 58 26 113 26 123 0 31 19 18 26 -18z m-4610 -1911 c17 -12
53 -35 80 -50 69 -38 156 -107 198 -155 19 -23 60 -83 90 -134 30 -50 62 -97
70 -105 17 -13 21 -30 8 -30 -13 0 -109 95 -148 147 -53 71 -110 129 -184 188
-72 58 -170 154 -170 168 0 10 13 3 56 -29z m2134 -918 c41 -8 89 -19 105 -25
17 -5 51 -12 77 -16 116 -15 32 -21 -300 -21 -296 0 -351 2 -327 12 17 7 42
13 57 14 15 0 39 5 55 11 39 15 159 37 213 38 25 1 79 -6 120 -13z"/>
<path d="M4290 5667 c-72 -50 -75 -54 -82 -101 -18 -127 -166 -247 -325 -262
-53 -5 -79 -13 -119 -39 -85 -55 -130 -74 -201 -85 -79 -12 -146 -2 -202 31
-32 19 -41 20 -120 9 l-86 -12 -156 -157 -155 -156 -18 -105 c-35 -202 -36
-210 -26 -236 19 -49 12 -149 -16 -221 -14 -37 -30 -97 -35 -132 -20 -144
-120 -280 -234 -318 -55 -18 -64 -25 -107 -88 l-48 -69 0 -214 c0 -245 -9
-215 111 -378 39 -53 88 -105 118 -127 59 -43 103 -100 138 -177 14 -30 38
-84 55 -119 25 -52 32 -82 36 -151 3 -54 0 -101 -7 -123 -13 -37 -8 -85 23
-261 l14 -79 138 -138 c98 -99 146 -139 168 -143 168 -35 191 -37 237 -14 95
47 245 30 349 -38 29 -19 82 -46 118 -60 37 -15 90 -44 118 -66 50 -37 104
-103 104 -125 0 -11 130 -111 218 -169 l44 -28 193 0 194 0 58 38 c106 70 140
103 147 141 9 45 98 142 165 178 28 16 80 34 115 42 47 9 78 23 108 48 74 59
175 97 262 97 50 0 147 -32 167 -56 14 -17 35 -15 172 13 l77 16 105 104 c58
57 107 114 110 126 36 155 64 369 62 482 -1 81 3 111 21 160 12 32 22 77 22
98 0 153 151 357 265 357 32 0 54 22 121 125 l54 83 0 137 c0 147 -1 150 -63
242 -91 135 -191 262 -236 301 -71 60 -106 116 -141 223 -23 72 -30 112 -30
172 0 78 -11 158 -51 387 l-21 115 -121 122 c-120 121 -122 122 -188 138 -93
21 -115 19 -156 -12 -48 -37 -132 -53 -214 -41 -73 11 -166 57 -243 122 -28
23 -73 56 -101 74 -27 17 -66 47 -85 68 -40 42 -196 162 -319 246 l-85 58
-170 0 -171 -1 -75 -52z m-1140 -2002 c154 -291 284 -532 288 -536 4 -4 126
223 272 504 145 282 272 526 282 542 17 29 18 9 18 -627 l0 -658 -50 0 -50 0
-2 439 -3 438 -235 -439 c-180 -337 -237 -436 -245 -425 -5 8 -111 206 -235
439 l-225 425 -3 -438 -2 -439 -50 0 -50 0 0 657 c0 361 2 654 4 652 3 -3 131
-243 286 -534z m1292 452 c168 -61 313 -218 371 -402 31 -100 31 -290 0 -390
-75 -237 -277 -410 -503 -431 l-70 -7 0 633 0 633 70 -7 c38 -3 98 -16 132
-29z m244 5 c22 -16 68 -60 103 -98 75 -82 151 -230 175 -341 20 -92 20 -234
0 -326 -20 -93 -90 -236 -151 -312 -67 -82 -159 -155 -196 -155 l-30 0 39 26
c133 89 245 254 289 429 23 90 24 255 2 347 -41 168 -149 330 -291 436 l-29
22 25 0 c14 0 43 -13 64 -28z m514 -372 l0 -400 390 0 390 0 0 400 0 400 55 0
55 0 0 -630 0 -630 -55 0 -55 0 0 175 0 175 -390 0 -390 0 0 -175 0 -175 -55
0 -55 0 0 630 0 630 55 0 55 0 0 -400z m186 328 c-3 -40 -10 -179 -16 -308
-14 -314 -15 -320 -46 -320 l-24 0 0 350 0 350 46 0 46 0 -6 -72z m889 5 c-3
-38 -12 -293 -20 -568 -19 -608 -20 -625 -46 -625 -19 0 -19 15 -19 630 l0
630 45 0 45 0 -5 -67z m-2996 -380 c132 -274 171 -362 166 -381 -4 -15 -10
-21 -15 -16 -8 8 -339 615 -391 717 l-19 37 43 0 43 0 173 -357z m861 -228 c0
-578 0 -585 -20 -585 -20 0 -20 7 -20 585 0 578 0 585 20 585 20 0 20 -7 20
-585z m-1033 -158 l36 -72 -17 -140 c-27 -224 -25 -215 -52 -215 l-24 0 0 256
c0 172 3 253 10 249 6 -4 27 -39 47 -78z m593 -211 c-100 -195 -127 -236 -144
-219 -3 3 52 117 122 253 112 219 127 244 130 214 3 -27 -19 -77 -108 -248z
m2200 19 c0 -13 -37 -15 -275 -15 l-275 0 0 -79 c0 -106 -8 -141 -31 -141 -18
0 -19 9 -19 125 l0 125 300 0 c260 0 300 -2 300 -15z"/>
<path d="M4350 3526 c0 -566 -10 -517 95 -467 199 95 317 339 271 561 -40 191
-181 356 -339 395 l-27 7 0 -496z m153 320 c17 -13 17 -28 7 -272 -14 -354
-17 -382 -41 -394 -19 -10 -19 -3 -19 345 0 389 -4 366 53 321z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
